<template>
    <div>
        <div align="center">
            <h4 class="q-mb-sm">Portafoglio Attivo</h4>
            Estrae tutte le pratiche in DEFINITA nei 12 mesi successivi al giorno selezionato
            <br><br>
            <hr>
        </div>
        <div class="row justify-center">

            <div class="col-12 col-md-4">
                <q-input outlined
                    v-model="data_estrazione"
                    mask="##/##/####"
                    label="Data di estrazione">

                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                            <q-date v-model="data_estrazione"
                                mask="DD/MM/YYYY">
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>
            </div>

            <div class="col-12 col-md-12" align="center">
                <br>
                <q-btn color="primary" label="Cerca" @click.native="onCercaNuovaProduzione"/>
                <br>
            </div>

            <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
                v-show="!is_loading"
                style="z-index: 1000;">
                <q-spinner-hourglass
                    size="4em"
                />
            </div>

            <div class="col-12 col-md-12">
                <q-table
                    title=""
                    :data="rows"
                    :columns="colonne"
                    color="primary"
                    row-key="name"
                    flat
                    :visible-columns="visibleColumns"
                    >
                    <template v-slot:top-right>
                        <q-btn
                        color="primary"
                        icon-right="archive"
                        label="ESPORTA IN CSV"
                        no-caps
                        @click="esportaTabella"
                        />
                    </template>
                </q-table>
            </div>

            <div class="col-12 col-md-12">
                <hr>
            </div>

            <div class="row col-12 col-md-12 justify-center">

                <div class="col-12 col-md-6" align="center">
                    <QQButton label="TORNA AL MENU"
                        color="blue-grey"
                        icon="undo"
                        size="md"
                        icon_align="left"
                        @click.native="onIndietroClicked()"
                    />
                </div>

            </div>

        </div>
    </div>
</template>
<script>
    //import { mapFields} from 'vuex-map-fields';
    import { mapState,mapActions } from 'vuex';
    import { exportFile } from 'quasar';
    import QQButton from "@/components/QQButton.vue";

    function wrapCsvValue (val, formatFn) {
        let formatted = formatFn !== void 0
            ? formatFn(val)
            : val

        formatted = formatted === void 0 || formatted === null
            ? ''
            : String(formatted)

        formatted = formatted.split('"').join('""')

        return `"${formatted}"`
    }

    export default {
        name: "PortafoglioAttivo",
        components: {
            QQButton
        },
        data() {
            return {
                data_estrazione: "",
                visibleColumns: ['nominativo','area','unita_operativa','numero','compagnia','decorrenza','scadenza','Premio','provvigioni']
            }
        },
        methods: {
            ...mapActions({
                fetchPortafoglioAttivo: "gestioneEstrazioni/fetchPortafoglioAttivo"
            }),
            esportaTabella() {
                // naive encoding to csv format
                const content = [ this.colonne.map(col => wrapCsvValue(col.label)) ].concat(
                    this.rows.map(row => this.colonne.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                        ? col.field(row)
                        : row[col.field === void 0 ? col.name : col.field],
                    col.format
                    )).join(','))
                ).join('\r\n')

                const status = exportFile(
                    'portafoglio-attivo-'+this.data_estrazione.replace("/","_")+'.csv',
                    content,
                    'text/csv'
                )

                if (status !== true) {
                    this.$q.notify({
                    message: 'Browser denied file download...',
                    color: 'negative',
                    icon: 'warning'
                    })
                }
            },
            async onCercaNuovaProduzione() {
                await this.fetchPortafoglioAttivo(this.data_estrazione);

                if (this.rows.length === 0) {
                    this.$q.dialog({
                        title: 'Attenzione',
                        message: 'Nessun dato identificato'
                    });
                }
            },
            onIndietroClicked() {
                this.$router.push({name : "Estrazioni"});
            }
        },
        computed: {
            //...mapFields("gestionePratiche", {
            //    RecordSelezionato: "RecordSelezionato"
            //}),
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                colonne: state => state.gestioneEstrazioni.colonne,
                rows: state => state.gestioneEstrazioni.righe,
                is_loading: state => state.gestioneEstrazioni.is_loading
            })
        },
        async mounted() {
            this.data_estrazione = "03/04/2022";
            //await this.fetchPortafoglioAttivo(this.data_estrazione);
            //console.log("righe,colonne:",this.colonne,this.rows);
        },
    }
</script>



