import { render, staticRenderFns } from "./PortafoglioAttivo.vue?vue&type=template&id=74d2149b&"
import script from "./PortafoglioAttivo.vue?vue&type=script&lang=js&"
export * from "./PortafoglioAttivo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerHourglass from 'quasar/src/components/spinner/QSpinnerHourglass.js';
import QTable from 'quasar/src/components/table/QTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QIcon,QPopupProxy,QDate,QBtn,QSpinnerHourglass,QTable});qInstall(component, 'directives', {ClosePopup});
